<template>
    <div>
        <List
            :columns="columns"
            :get_table_list="get_table_list"
            @handle_details_click="handle_details_click"
            :form_data_seo.sync="form_data_seo"
            :submit_preprocessing.sync="submit_preprocessing"
        >
			<template slot="right_btn">
                <a :href="'/manage/crm.export/leave' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>
		</List>
        <Details ref="details"></Details>
    </div>
</template>

<script>
import List from './components/list'
import { leaveList, leaveInfo, getType } from '@/api/approval'

import Details from "@/views/approval/leave/details";

const columns = [
    {
        title: "审批编号",
        dataIndex: "id",
    },
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "请假类型",
        dataIndex: "type",
    },
    {
        title: "开始时间",
        dataIndex: "start_time",
    },
    {
        title: "结束时间",
        dataIndex: "end_time",
    },
    {
        title: "请假事由",
        dataIndex: "desc",
    },
    {
        title: "审批状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
        List,
		Details
    },
    data() {
        return {
            columns,
            get_table_list: leaveList,
            visible: false,
            
            submit_preprocessing: {
				
            },
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: 'select',
                        name: 'type',
                        title: '请假类型',
                        list: [

                        ]
                    }
                ]
            }
        }
    },
    created() {
        getType({
            data: {
                type: 'leave'
            }
        }).then(res => {
            let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type', 'list', type_list)
        })
    },
    methods: {
        handle_details_click({ record }) {
			this.$refs.details.get_details(record);
        }
    }
}
</script>

<style lang="less" scoped>
.leave_img {
    width: 100px;
    height: 100px;
}
</style>